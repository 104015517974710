body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: 'Domine', serif;
}

a {
  text-decoration: none;
  color: #000000;
  font-weight: 600;
}

.navigation {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 50vw;
  margin: 2vh 0 0 30vw;
}

.content {
  width: 80vw;
  min-height: 45vh;
  margin: 20vh auto 10vh auto;
}

.project {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 5vh 0;
}

.project-content {
  width: 45vw;
}

.project-img {
  width: 60vw;
  height: auto;
  margin-right: 7vw;
}

.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 5vh;
}

.links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 20vw;
  margin: 2vh;
}


/* Grow decoration */

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}



@media only screen  and (min-width : 600px) {
    .navigation {
      margin: 2vh 0 0 50vw;
    }

    .project {
      -webkit-flex-direction: row;
              flex-direction: row;
    }

    .project-img {
      width: 20vw;
      height: auto;
    }
  }
