h1 {
  font-family: 'Domine', serif;
}

a {
  text-decoration: none;
  color: #000000;
  font-weight: 600;
}

.navigation {
  display: flex;
  justify-content: space-between;
  width: 50vw;
  margin: 2vh 0 0 30vw;
}

.content {
  width: 80vw;
  min-height: 45vh;
  margin: 20vh auto 10vh auto;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5vh 0;
}

.project-content {
  width: 45vw;
}

.project-img {
  width: 60vw;
  height: auto;
  margin-right: 7vw;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5vh;
}

.links {
  display: flex;
  justify-content: space-between;
  width: 20vw;
  margin: 2vh;
}


/* Grow decoration */

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.04);
}



@media only screen  and (min-width : 600px) {
    .navigation {
      margin: 2vh 0 0 50vw;
    }

    .project {
      flex-direction: row;
    }

    .project-img {
      width: 20vw;
      height: auto;
    }
  }